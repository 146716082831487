import { FC } from 'react'
import { default as MuiTab, TabProps as MuiTabProps } from '@mui/material/Tab'

export type TabProps = Pick<
  MuiTabProps,
  'disabled' | 'icon' | 'iconPosition' | 'label' | 'value' | 'wrapped' | 'onClick' | 'component'
>

/**
 * Used by the `Tabs` component to display a single tab.
 *
 * @see https://mui.com/material-ui/react-tabs for more details.
 *
 * @note Color is set by the `Tabs` component.
 *
 * @note Component overrides are defined in [./Tab.overrides.ts](./Tab.overrides.ts).
 */
export const Tab: FC<TabProps> = (props: TabProps) => {
  return <MuiTab {...props} sx={{ textTransform: 'none' }} />
}
