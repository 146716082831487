export default {
  orgAtSeatLimit: 'orgAtSeatLimit',
  userClickedOnOrgDropdownMenu: 'userClickedOnOrgDropdownMenu',
  userSearchedForMember: 'userSearchedForMember',
  userSearchedForTeam: 'userSearchedForTeam',
  userClickedOnRolesDropdown: 'userClickedOnRolesDropdown',
  userClickedOnAdminRole: 'userClickedOnAdminRole',
  userClickedOnMemberRole: 'userClickedOnMemberRole',
  userClickedOnViewOnlyRole: 'userClickedOnViewOnlyRole',
  userRemovedInRolesDropdown: 'userRemovedInRolesDropdown',
  userSortedMembersByName: 'userSortedMembersByName',
  userSortedMembersByRole: 'userSortedMembersByRole',
  userClickedOnAddNewMember: 'userClickedOnAddNewMember',
  userSelectedTeamsWhenAddingNewMember: 'userSelectedTeamsWhenAddingNewMember',
  userInvitedNewMember: 'userInvitedNewMember',
  userClickedOnTeamDirectoryTab: 'userClickedOnTeamDirectoryTab',
  userClickedOnMemberDirectoryTab: 'userClickedOnMemberDirectoryTab',
  userClickedOnCreateNewTeam: 'userClickedOnCreateNewTeam',
  userAddMembersWhenCreatingTeam: 'userAddMembersWhenCreatingTeam',
  userCreatedTeam: 'userCreatedTeam',
  userClickedOnEditTeamIcon: 'userClickedOnEditTeamIcon',
  userAddedMemberToTeam: 'userAddedMemberToTeam',
  userDeletedTeam: 'userDeletedATeam',
  userSavedChangesToTeam: 'userSavedChangesToTeam',
  userInvitedAnExistingMember: 'userInvitedAnExistingMember',
  userInvitedAUserFromAnotherOrg: 'userInvitedAUserFromAnotherOrg',
  Flowteams_EmptyState_Clicked_Action_Button: 'Flowteams_EmptyState_Clicked_Action_Button',
  Flowteams_InvitationsTab_Clicked_TableSortFilter:
    'Flowteams_InvitationsTab_Clicked_TableSortFilter',
  Flowteams_InvitationsTab_Clicked_KebabMenuOption:
    'Flowteams_InvitationsTab_Clicked_KebabMenuOption',
  Flowteams_MembersTable_Clicked_SortBy_LastActive:
    'Flowteams_MembersTable_Clicked_SortBy_LastActive',
  Flowteams_TeamTable_TeamMembersTab_Clicked_AddTeamMember:
    'Flowteams_TeamTable_TeamMembersTab_Clicked_AddTeamMember',
  Flowteams_TeamTable_SubteamsTab_Clicked_CreateSubteam:
    'Flowteams_TeamTable_SubteamsTab_Clicked_CreateSubteam',
  Flowteams_TeamTable_AssetsTab_Clicked_TableSortFilter:
    'Flowteams_TeamTable_AssetsTab_Clicked_TableSortFilter',
  Flowteams_TeamTable_AssetsTab_Clicked_Asset: 'Flowteams_TeamTable_AssetsTab_Clicked_Asset',
  Flowteams_NavBar_Clicked_BackButton: 'Flowteams_NavBar_Clicked_BackButton',
  Flowteams_MembersTable_Hovered_AdditionalTeamsTooltip:
    'Flowteams_MembersTable_Hovered_AdditionalTeamsTooltip',
  Flowteams_Invite_Modal_Clicked_Invite_By_Link_Tab:
    'Flowteams_Invite_Modal_Clicked_Invite_By_Link_Tab',
  Flowteams_Invite_Modal_Clicked_Invite_By_Email_Tab:
    'Flowteams_Invite_Modal_Clicked_Invite_By_Email_Tab',
  Flowteams_Invite_Modal_Email_Tab_Focused_Email_Field:
    'Flowteams_Invite_Modal_Email_Tab_Focused_Email_Field',
  Flowteams_Invite_Modal_Email_Tab_Focused_Teams_Field:
    'Flowteams_Invite_Modal_Email_Tab_Focused_Teams_Field',
  Flowteams_Invite_Modal_Email_Tab_Clicked_Role_Field:
    'Flowteams_Invite_Modal_Email_Tab_Clicked_Role_Field',
  Flowteams_Invite_Modal_Email_Tab_Clicked_Add_Member_Button:
    'Flowteams_Invite_Modal_Email_Tab_Clicked_Add_Member_Button',
  Flowteams_Invite_Clicked_Accept_Invite: 'Flowteams_Invite_Clicked_Accept_Invite',
  Flowteams_Invite_Modal_Email_Tab_Typed_Email_Field:
    'Flowteams_Invite_Modal_Email_Tab_Typed_Email_Field',
  Flowteams_Autosharing_Clicked_Toggle: 'Flowteams_Autosharing_Clicked_Toggle',
  Flowteams_Autosharing_Modal_Clicked_Enable: 'Flowteams_Autosharing_Modal_Clicked_Enable',
  Flowteams_Autosharing_Modal_Clicked_Disable: 'Flowteams_Autosharing_Modal_Clicked_Disable',
  Flowteams_Autosharing_Modal_Clicked_Cancel: 'Flowteams_Autosharing_Modal_Clicked_Cancel'
}
