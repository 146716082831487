import { AUTHENTICATION_V2_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { ClientSideError, ClientSideErrorType, logger } from '@dtx-company/logger'
import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { flowFetch } from '@app/code/src/utils/flowFetch'
import { useAuthState } from '../../../hooks/useAuthState'
import { useEffect, useMemo } from 'react'
import { usePrelogin } from '../../../hooks/auth/usePrelogin'
import useSWR from 'swr'

function buildUrl(invitationLinkId: string, orgName?: string, ssoDomain?: string): string {
  const pathname = `/accept-org-invite/${encodeURIComponent(invitationLinkId)}`
  const url = new URL(pathname, FLOWCODE_ROOT)
  if (orgName) url.searchParams.append('org', orgName)
  if (ssoDomain) url.searchParams.append('ssoDomain', ssoDomain)
  else url.searchParams.append('userExists', 'false')
  return url.toString()
}

export function useInvitationLink(props: {
  orgId?: string
  linkType: 'team' | 'org'
  teamId?: string
}): {
  invitationLink: string | null
  error: Error | null
} {
  const { orgId, teamId, linkType } = props
  const authState = useAuthState()
  const { token, org } = authState
  const userEmail = authState.jwt?.email
  const { checkIfEmailSSOEnabled, emailIsSSOEnabled, loading: ssoCheckLoading } = usePrelogin()

  const ssoDomain = useMemo(() => {
    if (!emailIsSSOEnabled) return undefined
    if (!userEmail) return undefined
    // chop everything up to the @ to get the domain portion
    return userEmail.replace(/^.+@/, '')
  }, [emailIsSSOEnabled, userEmail])

  // Check for SSO
  useEffect(() => {
    if (!userEmail) return
    checkIfEmailSSOEnabled(userEmail)
  }, [checkIfEmailSSOEnabled, userEmail])

  const idForLinkType = linkType === 'team' ? teamId : orgId
  // Fetch invitation link id
  const { data: invitationLink, error } = useSWR(idForLinkType, async (): Promise<string> => {
    const res = await flowFetch<{ invitationLinkId: string }>(
      'GET',
      `${AUTHENTICATION_V2_ENDPOINT}/${linkType}/${idForLinkType ?? ''}/invitation-link`,
      { technicalArea: 'monetization' },
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    )
    if (!res) {
      /**
       * This should NEVER happen.
       */
      const error = new ClientSideError({
        name: 'invite-link-not-found',
        message: 'No response from server',
        type: ClientSideErrorType.FetchError
      })
      logger.logError(error, {
        team: 'platform-products',
        tag: 'global_search'
      })
      throw error
    }

    const invitationLink = res.data.invitationLinkId
    return buildUrl(invitationLink, org?.name, ssoDomain)
  })

  return {
    invitationLink: (!ssoCheckLoading && invitationLink) || null,
    error
  }
}
