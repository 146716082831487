import { FC } from 'react'
import { default as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material/Tabs'

export type TabsProps = Pick<
  MuiTabsProps,
  | 'aria-label'
  | 'aria-labelledby'
  | 'allowScrollButtonsMobile'
  | 'centered'
  | 'children'
  | 'onChange'
  | 'orientation'
  | 'scrollButtons'
  | 'value'
  | 'variant'
  | 'visibleScrollbar'
>

/**
 * Tabs organize and allow navigation between groups of content that are related and at the same level of hierarchy.
 */

export const Tabs: FC<TabsProps> = (props: TabsProps) => {
  return <MuiTabs {...props} textColor="primary" indicatorColor="primary" />
}
