import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography
} from '@dtx-company/design-system/src'
import { FC } from 'react'
import { copyToClipboard } from '@dtx-company/app/shared/code/src/utils/flowcode'
import events from '@dtx-company/inter-app/src/event-tracking/events/org'

export const InvitationLinkDialogContent: FC<{ invitationLink: string }> = ({ invitationLink }) => {
  return (
    <>
      <DialogContent data-testid="link-tab-content">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={theme => ({ mb: theme.spacing(4), width: '100%' })}>
            <Typography variant="bodyMedium" color="onSurface.secondary" align="left">
              Invite members to your organization by sharing this invitation link.
            </Typography>
          </Box>
          <TextField
            hiddenLabel
            label="Invite link"
            data-testid="invitation-link-input"
            InputProps={{
              readOnly: true
            }}
            inputProps={{
              onCopy: () => events.Revenue_Org_ManuallyCopied_InviteLink(),
              onClick: e => e.currentTarget.select()
            }}
            value={invitationLink}
            fullWidth={true}
            helperText="Note: Users invited with the link are granted Member permissions."
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          data-testid="copy-invitation-link-button"
          onClick={() => {
            events.Revenue_Org_Clicked_CopyInviteLinkButton()
            copyToClipboard(invitationLink, 'Invitation link copied.')
          }}
        >
          Copy Link
        </Button>
      </DialogActions>
    </>
  )
}
