import { AnalyticsIntegrations, fireAnalyticsEvent } from '../helpers/fireAnalyticsEvent'
import { fireHeapEvent } from '../helpers'
import eventNames from '../constants/orgAdmin'

export const events = {
  orgAtSeatLimit(): void {
    const { orgAtSeatLimit } = eventNames
    fireHeapEvent(orgAtSeatLimit)
  },
  userClickedOnOrgDropdownMenu(): void {
    const { userClickedOnOrgDropdownMenu } = eventNames
    fireHeapEvent(userClickedOnOrgDropdownMenu)
  },
  userSearchedForMember(query: string): void {
    const { userSearchedForMember } = eventNames
    fireHeapEvent(userSearchedForMember, { query })
  },
  userSearchedForTeam(query: string): void {
    const { userSearchedForTeam } = eventNames
    fireHeapEvent(userSearchedForTeam, { query })
  },
  userClickedOnRolesDropdown(): void {
    const { userClickedOnRolesDropdown } = eventNames
    fireHeapEvent(userClickedOnRolesDropdown)
  },
  userClickedOnAdminRole(): void {
    const { userClickedOnAdminRole } = eventNames
    fireHeapEvent(userClickedOnAdminRole)
  },
  userClickedOnMemberRole(): void {
    const { userClickedOnMemberRole } = eventNames
    fireHeapEvent(userClickedOnMemberRole)
  },
  userClickedOnViewOnlyRole(): void {
    const { userClickedOnViewOnlyRole } = eventNames
    fireHeapEvent(userClickedOnViewOnlyRole)
  },
  userRemovedInRolesDropdown(): void {
    const { userRemovedInRolesDropdown } = eventNames
    fireHeapEvent(userRemovedInRolesDropdown)
  },
  userSortedMembersByName(): void {
    const { userSortedMembersByName } = eventNames
    fireHeapEvent(userSortedMembersByName)
  },
  userSortedMembersByRole(): void {
    const { userSortedMembersByRole } = eventNames
    fireHeapEvent(userSortedMembersByRole)
  },
  userClickedOnAddNewMember(): void {
    const { userClickedOnAddNewMember } = eventNames
    fireHeapEvent(userClickedOnAddNewMember)
  },
  userSelectedTeamsWhenAddingNewMember(teams: string[]): void {
    const { userSelectedTeamsWhenAddingNewMember } = eventNames
    fireHeapEvent(userSelectedTeamsWhenAddingNewMember, { teams })
  },
  userInvitedNewMember(): void {
    const { userInvitedNewMember } = eventNames
    fireHeapEvent(userInvitedNewMember)
  },
  userClickedOnTeamDirectoryTab(): void {
    const { userClickedOnTeamDirectoryTab } = eventNames
    fireHeapEvent(userClickedOnTeamDirectoryTab)
  },
  userClickedOnMemberDirectoryTab(): void {
    const { userClickedOnMemberDirectoryTab } = eventNames
    fireHeapEvent(userClickedOnMemberDirectoryTab)
  },
  userClickedOnCreateNewTeam(): void {
    const { userClickedOnCreateNewTeam } = eventNames
    fireHeapEvent(userClickedOnCreateNewTeam)
  },
  userAddMembersWhenCreatingTeam(): void {
    const { userAddMembersWhenCreatingTeam } = eventNames
    fireHeapEvent(userAddMembersWhenCreatingTeam)
  },
  userCreatedTeam(team: string): void {
    const { userCreatedTeam } = eventNames
    fireHeapEvent(userCreatedTeam, { team })
  },
  userClickedOnEditTeamIcon(teamId: string): void {
    const { userClickedOnEditTeamIcon } = eventNames
    fireHeapEvent(userClickedOnEditTeamIcon, { teamId })
  },
  userAddedMemberToTeam(ithacaId: string): void {
    const { userAddedMemberToTeam } = eventNames
    fireHeapEvent(userAddedMemberToTeam, { ithacaId })
  },
  userDeletedTeam(): void {
    const { userDeletedTeam } = eventNames
    fireHeapEvent(userDeletedTeam)
  },
  userSavedChangesToTeam(): void {
    const { userSavedChangesToTeam } = eventNames
    fireHeapEvent(userSavedChangesToTeam)
  },
  userInvitedAnExistingMember(): void {
    const { userInvitedAnExistingMember } = eventNames
    fireHeapEvent(userInvitedAnExistingMember)
  },
  userInvitedAUserFromAnotherOrg(): void {
    const { userInvitedAUserFromAnotherOrg } = eventNames
    fireHeapEvent(userInvitedAUserFromAnotherOrg)
  },
  Flowteams_MembersTable_Clicked_SortBy_LastActive(): void {
    const { Flowteams_MembersTable_Clicked_SortBy_LastActive } = eventNames
    fireHeapEvent(Flowteams_MembersTable_Clicked_SortBy_LastActive)
  },
  Flowteams_TeamTable_TeamMembersTab_Clicked_AddTeamMember(): void {
    const { Flowteams_TeamTable_TeamMembersTab_Clicked_AddTeamMember } = eventNames
    fireHeapEvent(Flowteams_TeamTable_TeamMembersTab_Clicked_AddTeamMember)
  },
  Flowteams_TeamTable_SubteamsTab_Clicked_CreateSubteam(): void {
    const { Flowteams_TeamTable_SubteamsTab_Clicked_CreateSubteam } = eventNames
    fireHeapEvent(Flowteams_TeamTable_SubteamsTab_Clicked_CreateSubteam)
  },
  Flowteams_TeamTable_AssetsTab_Clicked_TableSortFilter(sortBy: string): void {
    const { Flowteams_TeamTable_AssetsTab_Clicked_TableSortFilter } = eventNames
    fireHeapEvent(Flowteams_TeamTable_AssetsTab_Clicked_TableSortFilter, { sortBy })
  },
  Flowteams_TeamTable_AssetsTab_Clicked_Asset(assetType: string, assetId: string): void {
    const { Flowteams_TeamTable_AssetsTab_Clicked_Asset } = eventNames
    fireHeapEvent(Flowteams_TeamTable_AssetsTab_Clicked_Asset, { assetType, assetId })
  },
  Flowteams_NavBar_Clicked_BackButton(): void {
    const { Flowteams_NavBar_Clicked_BackButton } = eventNames
    fireHeapEvent(Flowteams_NavBar_Clicked_BackButton)
  },
  Flowteams_MembersTable_Hovered_AdditionalTeamsTooltip(): void {
    const { Flowteams_MembersTable_Hovered_AdditionalTeamsTooltip } = eventNames
    fireHeapEvent(Flowteams_MembersTable_Hovered_AdditionalTeamsTooltip)
  },
  Flowteams_Invite_Modal_Clicked_Invite_By_Link_Tab(): void {
    const { Flowteams_Invite_Modal_Clicked_Invite_By_Link_Tab } = eventNames
    fireHeapEvent(Flowteams_Invite_Modal_Clicked_Invite_By_Link_Tab)
  },
  Flowteams_Invite_Modal_Clicked_Invite_By_Email_Tab(): void {
    const { Flowteams_Invite_Modal_Clicked_Invite_By_Email_Tab } = eventNames
    fireHeapEvent(Flowteams_Invite_Modal_Clicked_Invite_By_Email_Tab)
  },
  Flowteams_Invite_Modal_Email_Tab_Focused_Teams_Field(): void {
    const { Flowteams_Invite_Modal_Email_Tab_Focused_Teams_Field } = eventNames
    fireHeapEvent(Flowteams_Invite_Modal_Email_Tab_Focused_Teams_Field)
  },
  Flowteams_Invite_Modal_Email_Tab_Clicked_Role_Field(): void {
    const { Flowteams_Invite_Modal_Email_Tab_Clicked_Role_Field } = eventNames
    fireHeapEvent(Flowteams_Invite_Modal_Email_Tab_Clicked_Role_Field)
  },
  Flowteams_Invite_Modal_Email_Tab_Clicked_Add_Member_Button(): void {
    const { Flowteams_Invite_Modal_Email_Tab_Clicked_Add_Member_Button } = eventNames
    fireAnalyticsEvent(Flowteams_Invite_Modal_Email_Tab_Clicked_Add_Member_Button, {}, [
      AnalyticsIntegrations.HEAP,
      AnalyticsIntegrations.INTERCOM
    ])
  },
  Flowteams_Invite_Clicked_Accept_Invite(): void {
    const { Flowteams_Invite_Clicked_Accept_Invite } = eventNames
    fireHeapEvent(Flowteams_Invite_Clicked_Accept_Invite)
  },
  Flowteams_Invite_Modal_Email_Tab_Typed_Email_Field(): void {
    const { Flowteams_Invite_Modal_Email_Tab_Typed_Email_Field } = eventNames
    fireAnalyticsEvent(Flowteams_Invite_Modal_Email_Tab_Typed_Email_Field)
  },
  Flowteams_Autosharing_Clicked_Toggle(args: { teamId: string; isTogglingOn: boolean }): void {
    fireAnalyticsEvent(eventNames.Flowteams_Autosharing_Clicked_Toggle, args)
  },
  Flowteams_Autosharing_Modal_Clicked_Enable(): void {
    fireAnalyticsEvent(eventNames.Flowteams_Autosharing_Modal_Clicked_Enable)
  },
  Flowteams_Autosharing_Modal_Clicked_Disable(): void {
    fireAnalyticsEvent(eventNames.Flowteams_Autosharing_Modal_Clicked_Disable)
  },
  Flowteams_Autosharing_Modal_Clicked_Cancel(args: { modalType: 'enable' | 'disable' }): void {
    fireAnalyticsEvent(eventNames.Flowteams_Autosharing_Modal_Clicked_Cancel, args)
  }
}

export default events
