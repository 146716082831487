import { Banner } from '@dtx-company/design-system/src'
import useTranslation from 'next-translate/useTranslation'

export function InvalidLinkBanner(): React.ReactElement {
  const { t } = useTranslation('org-invites')
  return (
    <Banner
      color="error"
      title={t('InviteUsers.errors.noInvitationLink.title')}
      description={t('InviteUsers.errors.noInvitationLink.description')}
    />
  )
}
