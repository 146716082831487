import { AnalyticsIntegrations, fireAnalyticsEvent } from '../helpers/fireAnalyticsEvent'
import { fireHeapEvent } from '../helpers'
import eventNames from '../constants/org'

const events = {
  Revenue_Org_ManuallyCopied_InviteLink(): void {
    const { Revenue_Org_ManuallyCopied_InviteLink } = eventNames
    fireHeapEvent(Revenue_Org_ManuallyCopied_InviteLink)
  },
  Revenue_Org_Clicked_CopyInviteLinkButton(): void {
    const { Revenue_Org_Clicked_CopyInviteLinkButton } = eventNames
    fireAnalyticsEvent(Revenue_Org_Clicked_CopyInviteLinkButton, {}, [
      AnalyticsIntegrations.HEAP,
      AnalyticsIntegrations.INTERCOM
    ])
  },
  Revenue_Org_Clicked_AcceptInvite(): void {
    const { Revenue_Org_Clicked_AcceptInvite } = eventNames
    fireHeapEvent(Revenue_Org_Clicked_AcceptInvite)
  }
}

export default events
